import React, { Component, Suspense } from "react";

// import Team from "../../component/Team";
// import Blog from "../../component/Blog";
// import Contact from "../../component/Contact";
// import Footer from "../../component/Footer/Footer";

// Importing Section
const Navbar = React.lazy(() => import("./Navbar/NavBar"));

const Section = React.lazy(() => import("./Section"));
const Services = React.lazy(() => import("./Services"));
const Feature = React.lazy(() => import("./Feature"));
const Prices = React.lazy(() => import("./Prices"));
const Pricing = React.lazy(() => import("./PricingLegacy"));
const Team = React.lazy(() => import("./Team"));
const Blog = React.lazy(() => import("./Blog"));
const Contact = React.lazy(() => import("./Contact"));
const Footer = React.lazy(() => import("./Footer/Footer"));
const FoundersNote = React.lazy(() => import("./FoundersNote"));
const HowItWorks = React.lazy(() => import("./HowItWorks"));
const Faq = React.lazy(() => import("./Faq"));
const FooterComponent = React.lazy(() => import("./FooterComponent"));
const References = React.lazy(() => import("./References"));

// import { Spinner } from "reactstrap";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "howitworks", navheading: "How It Works" },
        // { id: 3, idnm: "foundersnote", navheading: "Founders Note" },
        { id: 4, idnm: "pricing", navheading: "Pricing" },
        // { id: 2, idnm: "services", navheading: "Services" },
        // { id: 3, idnm: "features", navheading: "Features" },
        // { id: 4, idnm: "pricing", navheading: "Pricing" },
        // { id: 5, idnm: "team", navheading: "Team" },
        // { id: 6, idnm: "blog", navheading: "Blog" },
        { id: 5, idnm: "footer", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };

  //set preloader div
  PreLoader = () => {
    return <div id="preloader2"></div>;
  };

  render() {
    return (
      <React.Fragment>
        <Suspense
          fallback={this.PreLoader()}
          // fallback={() => null}
        >
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Importing Section */}
          <Section />

          {/* Importing Section */}
          {/* <Services /> */}
          <HowItWorks />

          {/* Importing Feature */}
          {/* <Feature /> */}
          {/* <Faq /> */}

          {/* Importing Pricing */}
          <Prices />
          {/* <Pricing /> */}

          {/* Importing Pricing */}
          {/* <Team /> */}

          {/* Importing Blog */}
          {/* <Blog /> */}

          {/* Importing Contact */}
          <FoundersNote />
          {/* <Contact /> */}

          {/* Importing Footer */}

          {/* <Footer />
           */}
          <References />
          <FooterComponent />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default LandingPage;
