import Login from "../src/pages/Login";
import Signup from "../src/pages/Signup";
import PswForget from "../src/pages/PswForget";
// import Layout1 from "./pages/Layout1/Layout_1";
// import Layout2 from "../src/pages/Layout2/Layout2";
// import Layout3 from "../src/pages/Layout3/Layout3";
// import Layout4 from "../src/pages/Layout4/Layout4";
// import Layout5 from "../src/pages/Layout5/Layout5";
// import Layout6 from "../src/pages/Layout6/Layout6";
// import Layout7 from "../src/pages/Layout7/Layout7";
// import Layout8 from "../src/pages/Layout8/Layout8";
// import Layout9 from "../src/pages/Layout9/Layout9";
import LandingPage from "./component/LandingPageOutline";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsOfService from "./component/TermsOfService";

const Oops = () => {
  return (
    <p>
      Oops. the app hasn't launched yet. This is just a demo version of the
      landing page for getting feedback. It'll have a link to the real page
      soon!
    </p>
  );
};

const routes = [
  // { path: "/password-forget", component: PswForget },
  // { path: "/sign-up", component: Signup },
  // { path: "/login", component: Login },
  // { path: "/Layout2", component: <Layout2 /> },
  // { path: "/Layout3", component: <Layout3 /> },
  // { path: "/Layout4", component: <Layout4 /> },
  // { path: "/Layout5", component: <Layout5 /> },
  // { path: "/Layout6", component: <Layout6 /> },
  // { path: "/Layout7", component: <Layout7 /> },
  // { path: "/Layout8", component: <Layout8 /> },
  // { path: "/Layout9", component: <Layout9 /> },
  { path: "/", component: <LandingPage /> },
  { path: "privacy-policy", component: <PrivacyPolicy /> },
  { path: "terms-of-service", component: <TermsOfService /> },
  { path: "oops", component: <Oops /> },
];

export default routes;
